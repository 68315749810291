import React from "react";

import styled from 'styled-components'

import { useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import SEO from "../components/seo"
import Layout from "../components/layout"
import TextBlock from '../components/textBlock'
import Hero from '../components/hero'
import SectionTitle from '../components/sectionTitle'

const OurStoryPage = () => {

    const data = useStaticQuery(graphql`
        query {
            hero: file(relativePath: { eq: "Engagement-57.jpg" }) {
                childImageSharp {
                fluid(maxWidth: 1440) {
                    ...GatsbyImageSharpFluid
                }
                }
            },
        timeline1: file(relativePath: { eq: "pumpkinpie.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        timeline2: file(relativePath: { eq: "mudtug.jpg" }) {
            childImageSharp {
            fluid(maxWidth: 1440) {
                ...GatsbyImageSharpFluid
            }
            }
        }
        timeline3: file(relativePath: { eq: "102011-21st-birthday.jpg" }) {
          childImageSharp {
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
          }
          }
        }
        timeline4: file(relativePath: { eq: "102012-halloween.jpg" }) {
          childImageSharp {
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid
          }
          }
      }
      timeline5: file(relativePath: { eq: "072013-mairners-game-seattle.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      }
      timeline6: file(relativePath: { eq: "052014-night-out-rochester-kozn.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      }
      timeline7: file(relativePath: { eq: "052015-grad.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      } 
      timeline8: file(relativePath: { eq: "072015-niagarafalls-ca.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      } 
      timeline9: file(relativePath: { eq: "052016-oxford-ms.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      } 
      timeline10: file(relativePath: { eq: "122016-syracuse-skating.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      } 
      timeline11: file(relativePath: { eq: "052017-myrtle.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      } 
      timeline12: file(relativePath: { eq: "032018-wilmington-interview.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      } 
      timeline13: file(relativePath: { eq: "062019-champions-league-raleigh.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      } 
      timeline14: file(relativePath: { eq: "062019-charleston-mikey.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      } 
      timeline15: file(relativePath: { eq: "11272019-engagement.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      } 
      timeline16: file(relativePath: { eq: "122019-engagement-party.jpg" }) {
        childImageSharp {
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
        }
        }
      } 
    }
    `)

  const HeaderContainer = styled.div ({
    backgroundColor: '#fff',
    padding: '24px',
    boxSizing: 'border-box',
    width: 'calc(100% - 32px)',
    display: 'block',
    margin: 'auto',
    'box-shadow': '0px 0px 12px 4px rgba(0, 0, 0, 0.2)',
    borderRadius: '16px',
    marginTop: "-24px",
    position: 'relative',
    'z-index': '10',
  })

  const TimelineContainer = styled.div ({
    width: 'calc(100% - 32px)',
    display: 'block',
    margin: 'auto',
    position: 'relative',
    '&::before' : {
        content: '" "',
        position: 'absolute',
        width: '2px',
        height: '100%',
        left: '0px',
        top: '6px',
        background: '#e3e3e3'
    }
  })

  const TimelineSection = styled.div({
    position: 'relative',
    display: 'block',
    padding: '0px 0px 24px 24px',
    '&::before' : {
        content: '" "',
        position: 'absolute',
        width: '8px',
        height: '8px',
        left: '-3px',
        borderRadius: '100%',
        top: '6px',
        background: '#E9BA00'
    }
  })

  const TimelineDate = styled.span`
    font-weight: bold;
    color: #666;
    font-family: 'Lato', arial, sans-serif;
    font-size: 16px;
    display: block;
  `

  const TimelineTitle = styled.span`
    font-weight: 400;
    color: #666;
    font-family: 'Source Serif Pro', serif;
    font-size: 14px;
    display: block;
    margin-top: 8px;
  `

  const TimelineImage = styled(Img)`
    border-radius: 16px;
    width: calc( 100%);
    margin-top: 24px;
    height: auto;
  `
    
  return ( 
    <div>
      <Layout>
        <SEO title="Our Story" />
        <Hero file={data.hero.childImageSharp.fluid} style={{marginTop: '50px'}}/>
        <HeaderContainer>
            <TextBlock
                title="Samantha & Tony"
                subTitle="Our Story"
            >
                {/* Flashback to RIT Freshman Orientation 2011... Samantha & Tony were Orientation Assistants playing Civil War Tag... Tony protected Samantha during the game then proceeded to mildly stalk her the rest of the week.  It was cute.  Not at all creepy.
                <br /><br /> */}
                Flashback to RIT Freshman Orientation 2011... Samantha & Tony were working as Orientation Assistants &amp; it was love at first sight. Since September 17, 2011 Samantha and Tony have moved all around the country together supporting one another and the rest is history.
            </TextBlock>
        </HeaderContainer>
        <SectionTitle title="Timeline" style={{ margin: "24px 16px", display: "block" }}/>
        <TimelineContainer>
            <TimelineSection>
                    <TimelineDate>
                        September 17, 2011
                    </TimelineDate>
                    <TimelineTitle>
                        Officially Dating with a Side of Pie
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline1.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        September 2011
                    </TimelineDate>
                    <TimelineTitle>
                        One of Our First Dates - Mud Tug
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline2.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        October 2011
                    </TimelineDate>
                    <TimelineTitle>
                        Tony's 21st Birthday Wine Tour
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline3.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        October 2012
                    </TimelineDate>
                    <TimelineTitle>
                        Wilma &amp; Fred Halloween Costumes
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline4.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        July 2013
                    </TimelineDate>
                    <TimelineTitle>
                        LA Angels vs Seattle Mariners feat. Josh Hamilton
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline5.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        May 2014
                    </TimelineDate>
                    <TimelineTitle>
                        Night out in Rochester with the "Roomies"
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline6.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        May 2015
                    </TimelineDate>
                    <TimelineTitle>
                        Samantha's BS/ME Graduation from RIT
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline7.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        July 2015
                    </TimelineDate>
                    <TimelineTitle>
                        Trip to Marine Land / Clifton Hills in Niagara Falls, Canada
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline8.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        May 2016
                    </TimelineDate>
                    <TimelineTitle>
                        Normal Day Living in Oxford, MS
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline9.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        December 2016
                    </TimelineDate>
                    <TimelineTitle>
                        Ice Skating in Downtown Syracuse
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline10.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        May 2017
                    </TimelineDate>
                    <TimelineTitle>
                        Impromptu Myrtle Beach Vacation
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline11.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        March 2018
                    </TimelineDate>
                    <TimelineTitle>
                        Samantha's GE Wilmington Post Interview Beach Session
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline12.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        June 2019
                    </TimelineDate>
                    <TimelineTitle>
                        Celebrating Liverpool Championship in Raleigh
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline13.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        June 2019
                    </TimelineDate>
                    <TimelineTitle>
                        Charleston Carriage Tour with Mikey the Horse
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline14.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        November 27, 2019
                    </TimelineDate>
                    <TimelineTitle>
                        She said YES! at Cloud 9 in Wilmington
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline15.childImageSharp.fluid} />
            </TimelineSection>
            <TimelineSection>
                    <TimelineDate>
                        December 29, 2019
                    </TimelineDate>
                    <TimelineTitle>
                        Engagement Party in Buffalo
                    </TimelineTitle>
                    <TimelineImage fluid={data.timeline16.childImageSharp.fluid} />
            </TimelineSection>
        </TimelineContainer>
      </Layout>
    </div>  
  )
}

export default OurStoryPage